import "../lib/sentry";
import "../lib/hotjar";
import "@hotwired/turbo-rails";
import { Tooltip } from "bootstrap";
import "../lib/stimulus";
import "~/javascripts/multi_select_checkbox";
import "~/javascripts/toggler";

// Documentation: https://getbootstrap.com/docs/5.2/components/tooltips/#enable-tooltips
function initializeTooltips() {
  const tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'));
  tooltipTriggerList.map((tooltipTriggerEl) => new Tooltip(tooltipTriggerEl));
}

initializeTooltips();
document.addEventListener("turbo:render", () => {
  initializeTooltips();
});
