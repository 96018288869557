class MultiSelectCheckbox {
  constructor(checkbox) {
    this.checkbox = checkbox;
    this.id = checkbox.getAttribute("data-multiselect");
    this.span = document.querySelector(`#${this.id}`);
    this.checkboxes = this.span.querySelectorAll("input[type='checkbox']");
    this.checkbox.addEventListener("click", (_ev) => this.toggle());
    this.checkboxes.forEach((subCheckbox) => {
      subCheckbox.addEventListener("click", (_ev) => this.toggleSubCheckboxes());
    });
  }

  toggle() {
    this.checkboxes.forEach((checkbox) => {
      checkbox.checked = this.checkbox.checked;
    });
  }

  toggleSubCheckboxes() {
    if (
      this.checkboxes.length > this.span.querySelectorAll("input[type='checkbox']:checked").length &&
      this.span.querySelectorAll("input[type='checkbox']:checked").length > 0
    ) {
      this.checkbox.indeterminate = true;
      this.checkbox.checked = true;
    } else if (this.span.querySelectorAll("input[type='checkbox']:checked").length < 1) {
      this.checkbox.indeterminate = false;
      this.checkbox.checked = false;
    } else {
      this.checkbox.indeterminate = false;
    }
  }
}

window.addEventListener("turbo:load", () => {
  const elements = document.querySelectorAll("[data-multiselect]");
  elements.forEach((element) => new MultiSelectCheckbox(element));
});
